import React from 'react';
import Divider from '../../Divider';
import NumField from '../../NumField';
import { CapsuleTabContentBody, CapsuleTabContentHeader } from '../CapsuleComponents';
import { addDays, format } from 'date-fns/fp';
import { IFormState } from '../../../semshared/utils/lister';
import { set } from 'lodash';
import { LinkButton } from '../../LinkButton';

interface RoomSectionProps {
  formState: IFormState
  setFormState: (formState: IFormState) => void;
  setSimpleMode: (simpleMode: boolean) => void;
  simpleMode: boolean;
  setDrawerShow: (s: string) => void;
  setPopupShow: (s: string | null) => void;
}

const RoomsSection = ({ formState, setFormState, setSimpleMode, simpleMode, setDrawerShow, setPopupShow }: RoomSectionProps) => {

  const simple = () => {
    return <>
    <CapsuleTabContentHeader>Bitte geben Sie die Anzahl der Zimmer an.</CapsuleTabContentHeader>
    <CapsuleTabContentBody>
      {!formState.start && <div></div>}
      <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto, sans-serif',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            minWidth: 50,
            paddingRight: 40,
            textAlign: 'left',
          }}
        >
          <>
            <strong>Anreise Vortag</strong>
            <div>{format('dd.MM.yyyy', addDays(-1, formState.start || new Date()))}</div>
          </>
        </div>
        <div
          style={{
            paddingRight: 0,
            alignSelf: 'center',
          }}
        >
          <NumField
            value={formState.prevdayRoomsEZ}
            onChange={value =>
              setFormState({
                ...formState,
                prevdayRoomsEZ: value,
              })
            }
          />
        </div>
      </div>
      <Divider />
      {formState.start &&
      <div
      style={{
        display: 'flex',
        fontFamily: 'Roboto, sans-serif',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          minWidth: 50,
          paddingRight: 40,
          textAlign: 'left',
        }}
      >
        <>
          <strong>Seminartage</strong>
          <div>{format('dd.MM', addDays(0, formState.start || new Date()))} - {format('dd.MM.yy', addDays(formState.days.length - 1, formState.start || new Date()))}</div>
        </>
      </div>
      <div
        style={{
          paddingRight: 0,
          alignSelf: 'center',
        }}
      >
          <React.Fragment>
                <NumField
                  value={formState.days[0].overnightRoomsEZ}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                        if (formState.days.length - 1 !== i) {
                          return {
                            ...day,
                            overnightRoomsEZ: value,
                          };
                        } else {
                          return day;
                        }
                      }),
                    });
                  }}
                />
          </React.Fragment>
          </div>
          </div>}
          <Divider />
      {formState.start &&
      <div
      style={{
        display: 'flex',
        fontFamily: 'Roboto, sans-serif',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          minWidth: 50,
          paddingRight: 40,
          textAlign: 'left',
        }}
      >
        <>
          <strong>Zusatznacht</strong>
          <div>auf {format('dd.MM.yy', addDays(formState.days.length, formState.start || new Date()))}</div>
        </>
      </div>
      <div
        style={{
          paddingRight: 0,
          alignSelf: 'center',
        }}
      >
          <React.Fragment>
                <NumField
                  value={formState.days[formState.days.length - 1].overnightRoomsEZ}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                        if (formState.days.length - 1 === i) {
                          return {
                            ...day,
                            overnightRoomsEZ: value,
                          };
                        } else {
                          return day;
                        }
                      }
                      ),
                    });
                  }}
                />
          </React.Fragment>
          </div>
          </div>}
          <Divider />
          <LinkButton onClick={() => {
                   setDrawerShow('rooms')
                   setPopupShow(null)
                }}>⇾ Details</LinkButton>
    </CapsuleTabContentBody>
  </>
  }

  const advanced = () => {
    return <>
    <CapsuleTabContentBody>
    <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto, sans-serif',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            minWidth: 50,
            paddingRight: 40,
            textAlign: 'left',
          }}
        >
          
        </div>
        <div
          style={{
            paddingRight: 0,
            alignSelf: 'center',
            display: 'flex',
            gap:40,
            fontWeight: 'bold',
          }}
        >
          <div>Einzelzimmer</div>
          <div style={{ marginRight: 10 }}>Doppelzimmer</div>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto, sans-serif',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            minWidth: 50,
            paddingRight: 40,
            textAlign: 'left',
          }}
        >
          <>
            <strong>Anreise Vortag</strong>
            <div>{format('dd.MM.yyyy', addDays(-1, formState.start || new Date()))}</div>
          </>
        </div>
        <div
          style={{
            paddingRight: 0,
            alignSelf: 'center',
            display: 'flex',
            gap: 5,
          }}
        >
          <NumField
            value={formState.prevdayRoomsEZ}
            onChange={value =>
              setFormState({
                ...formState,
                prevdayRoomsEZ: value,
              })
            }
          />
          <NumField
            value={formState.prevdayRoomsDZ}
            onChange={value =>
              setFormState({
                ...formState,
                prevdayRoomsDZ: value,
              })
            }
          />
        </div>
      </div>
      <Divider />
      {formState.start &&
        [...Array(formState.days.length)].map((_, index) => (
          <React.Fragment key={index}>
            <div
              style={{
                display: 'flex',
                fontFamily: 'Roboto, sans-serif',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  minWidth: 50,
                  paddingRight: 40,
                  textAlign: 'left',
                }}
              >
                <>
                  <strong>{formState.days.length - 1 === index ? 'Verlängerungsnacht' : `Tag ${index + 1}`}</strong>
                  <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                </>
              </div>
              <div
                style={{
                  paddingRight: 0,
                  alignSelf: 'center',
                  display: 'flex',
            gap: 5,
                }}
              >
                <NumField
                  value={formState.days[index].overnightRoomsEZ}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                        if (index === i) {
                          return {
                            ...day,
                            overnightRoomsEZ: value,
                          };
                        } else {
                          return day;
                        }
                      }),
                    });
                  }}
                />
                <NumField
                  value={formState.days[index].overnightRoomsDZ}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                        if (index === i) {
                          return {
                            ...day,
                            overnightRoomsDZ: value,
                          };
                        } else {
                          return day;
                        }
                      }),
                    });
                  }}
                />
              </div>
            </div>
            <Divider />
          </React.Fragment>
        ))}
    </CapsuleTabContentBody>
  </>
  }

  return <>
  {simpleMode ? simple() : advanced()}
    </>
}

export default RoomsSection;
