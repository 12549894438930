import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import * as ReactDOMClient from 'react-dom/client';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Capsule from './components/capsule/Capsule';

// get our shadow HOST
const host = document.querySelector('#seminargo-widget');

// Read new attribute for auto updating the form state
const autoUpdateAttr = host?.getAttribute('data-auto-update');
const autoUpdate = autoUpdateAttr === "true" || autoUpdateAttr === "1";

interface Styles {
  label: string;
  button: string;
  input: string;
  custom: string;
  cssUrls: string[];
}

interface IFormInputs {
  name: string;
  lastname: string;
}

function addStylesheetURL(url: string) {
  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
}

// const styles = JSON.parse(host?.getAttribute('data-styles') || '{}');
let styles: Styles | null = null;
const main = async () => {
  /*const res = await fetch(
    process.env.BACKEND_URL
      ? "${process.env.BACKEND_URL}/style-mock"
      : "http://127.0.0.1:4030/style-mock"
  );
  styles = await res.json();*/

  const _toBool = (src: string | null | undefined) => {
    if (src == null || src == undefined) return false;
    if (src === 'N' || src === '0' || src === 'false') return false;
    return true;
  };

  const urlParams = new URLSearchParams(window.location.search);

  const hotelRef = urlParams.get('seminargoHotelRef') || host?.getAttribute('data-hotel-ref') || undefined;
  const platformUrl = urlParams.get('seminargoPlatformUrl') || host?.getAttribute('data-platform-url') || '';
  const hotelView = urlParams.get('seminargoHotelView') || host?.getAttribute('data-hotel-view') || null;
  

  const s = urlParams && urlParams.get('s')

  const styles = {
    label: '',
    button: '',
    input: '',
    custom: '',
    cssUrls: [],
  };

  const Label = styled.label`
    display: block;
    ${styles && styles.label}
  `;

  const Button = styled.input`
    ${styles && styles.button}
  `;

  const Input = styled.input`
    ${styles && styles.input}
  `;

  const Container = styled.div``;

  const ErrorText = styled.div`
    color: red;
  `;

  const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const Column = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    width: 0;StyleSheetManager
    text-align: left;
  `;

  const InputContainer = styled.div`
    padding: 10px;
    justify-content: start;
  `;

  let color: string = "#000"

  const time = new Date().getTime();
  let fontsCss = '';
  try {
    const res = await fetch(`${process.env.REACT_APP_WIDGET_URL}/fonts.css?v=${time}`);
    fontsCss = await res.text();
  } catch (err) {
    console.error(`Error fetching fonts.css: ${err}`);
  }

  const App = ({ shadowRoot }: any) => {
    if (process.env.REACT_APP_VERSION) {
      console.log(`SEM-Widget version: ${process.env.REACT_APP_VERSION}`);
    }

    return (
      <div id="seminargoWidgetRoot" style={{ all: 'initial' }}>
        <style>{fontsCss}</style>
        <center>
          <Capsule
            shadowRoot={shadowRoot}
            formState={s}
            platformUrl={platformUrl}
            label="Seminar buchen"
            hotelView={hotelView}
            autoUpdate={autoUpdate}
          />
        </center>
      </div>
    );
  };

  if (host) {
    // create a shadow root inside it
    const shadow = host.attachShadow({ mode: 'open' });

    // create a slot where we will attach the StyleSheetManager
    const styleSlot = document.createElement('section');
    styleSlot.innerHTML = '<style>:host { all: initial; } </style>';
    // append the styleSlot inside the shadow
    shadow.appendChild(styleSlot);

    // create a slot where we will attach the StyleSheetManager
    const styleSlotAdvanced = document.createElement('style');
    styleSlotAdvanced.innerHTML = (styles && styles.custom) || '';

    const pacStyles = document.createElement('style');
    pacStyles.innerHTML = `.pac-container { z-index: 999999 !important; }`;
    document.head.appendChild(pacStyles);

    // append the styleSlot inside the shadow
    shadow.appendChild(styleSlotAdvanced);

    const time = new Date().getTime();
    const fontsCss = document.createElement('link');
    fontsCss.setAttribute('href', `${process.env.REACT_APP_WIDGET_URL}/fonts.css?v=${time}`);
    fontsCss.setAttribute('rel', 'stylesheet');
    fontsCss.setAttribute('type', 'text/css');
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(fontsCss);

    // create the element where we would render our app
    const renderIn = document.createElement('div');
    // append the renderIn element inside the styleSlot
    styleSlot.appendChild(renderIn); 

    if (styles && styles.cssUrls) {
      styles.cssUrls.forEach(url => {
        addStylesheetURL(url);
      });
    }

    const root = ReactDOMClient.createRoot(renderIn);

    // render the app
    root.render(
      <StyleSheetManager target={styleSlot}>
        <App shadowRoot={shadow} />
      </StyleSheetManager>,
    );
  }
};

main();
